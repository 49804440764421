<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 15.5 15.107">
    <path
      id="Icon_awesome-arrow-right-2"
      :style="iconStyle"
      data-name="Icon awesome-arrow-right"
      d="M6.59,2.483l.768-.768a.827.827,0,0,1,1.173,0l6.725,6.722a.827.827,0,0,1,0,1.173L8.531,16.334a.827.827,0,0,1-1.173,0l-.768-.768A.831.831,0,0,1,6.6,14.38l4.169-3.971H.83A.828.828,0,0,1,0,9.578V8.471a.828.828,0,0,1,.83-.83h9.942L6.6,3.669A.825.825,0,0,1,6.59,2.483Z"
      transform="translate(0 -1.471)"
      fill="#fa6758"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '15.107',
    },
    width: {
      type: String,
      default: '15.5',
    },
    color: {
      type: String,
      default: '#FA6758',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
